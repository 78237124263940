import * as THREE from "three";
import Experience from "../../Experience.js";
export default class Photospheres {
  constructor() {
    this.experience = new Experience();
    this.scene = this.experience.scene;
    this.resources = this.experience.resources;
    // this.debug = this.experience.debug;
    this.photosphereGroup = new THREE.Group();
    this.geo = this.experience.world.geo;
    const geometry = new THREE.CylinderGeometry(4, 4, 4, 60, 1, true);
    geometry.scale(-1, 1, 1);
    const imageSource = "./PhotosphereImages/test2-warp-transformed.jpg";
    const image = new Image();
    image.src = imageSource;
    const texture = new THREE.Texture(image);
    const material = new THREE.MeshBasicMaterial({ map: texture });
    this.dummyPhotosphere = new THREE.Mesh(geometry, material);
    this.dummyPhotosphere.position.y = -3;
    image.addEventListener("load", () => {
      texture.needsUpdate = true;
    });

    this.photosphereGroup.add(this.dummyPhotosphere);
    // this.photosphereGroup.visible = false;
    this.scene.add(this.photosphereGroup);
    this.height = 1;
    this.width = 1;
    if (this.debug) {
      this.debugFolder = this.debug.ui.addFolder("Photospheres");
      this.debugFolder
        .add(this, "togglePhotosphereVisibility")
        .name("Toggle Photosphere");
    }
    // this.hidePhotosphere();
  }
  displayPhotosphere(id = null) {
    this.photosphereGroup.visible = true;
    // this.geo.geoGroup.visible = false;
    // console.log(this.geo.geoGroup);
  }
  hidePhotosphere() {
    this.photosphereGroup.visible = false;
    this.geo.geoGroup.visible = true;
  }
  togglePhotosphereVisibility() {
    // use displayPhotosphere and hidePhotosphere
    if (this.photosphereGroup.visible) {
      this.hidePhotosphere();
    } else {
      this.displayPhotosphere();
    }
  }
}
