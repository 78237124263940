export default [
  {
    name: "starTexture",
    type: "texture",
    path: "textures/5.png",
  },
  // {
  //     name: 'sampleModel',
  //     type: 'gltfModel',
  //     path: 'path/to/models/sushi.glb'
  // } ,
  {
    name: "font",
    type: "font",
    path: "helvetiker_regular.typeface.json",
  },
  {
    name: "timeScrubberMockup",
    type: "texture",
    path: "textures/timeScrubberMockup.png",
  },
];
